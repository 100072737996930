<template>
  <div class="workspace">
    <div class="workspace__tabs" style="padding: 6px 10px 0 10px">
      <el-tabs v-model="routesKey" :stretch="true" @tab-click="clickItem">
        <el-tab-pane label="待处理" name="todo"><todo/></el-tab-pane>
        <el-tab-pane label="已处理" name="about"><about/></el-tab-pane>
        <el-tab-pane label="我发起" name="apply"><apply/></el-tab-pane>
        <el-tab-pane label="抄送我" name="cc"><cc/></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import todo from "@/views/workspace_mini/todo/index.vue";
import apply from "@/views/workspace_mini/apply/index.vue";
import about from "@/views/workspace_mini/about/index.vue";
import cc from "@/views/workspace_mini/cc/index.vue";

export default {
  name: 'workSpace',
  components: {
    todo,about,apply,cc
  },
  data() {
    return {
      routesKey:"todo"
    }
  },
  created() {
    let title = this.$store.approverTitle;
    if (title){
      this.routesKey = title;
    }
  },
  methods: {
    clickItem(item){
      this.$store.approverTitle = item._props.name;
    }
  },
}
</script>

<style lang="less" scoped>
.workspace {
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 250px !important;
  max-width: 400px !important;
  &__header {
  }
  &__tabs {
    flex: 1;
    .route-tab {
      /deep/ .el-tabs__content {
        height: calc(100vh - 150px);
        overflow-y: auto;
      }
    }
  }
}
.arrow-left{
  font-weight: bold;
  font-size: 23px;
}
.title-name{
  position: absolute;
  left: 50%;
  top: 16px;
  transform:translateX(-50%);
  font-size: 15px;
  font-weight: bold
}
/* From Uiverse.io by Pradeepsaranbishnoi */

</style>
